import { superAdminRoles, userModuleRoles } from '@/defaults/roles';

export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/pages/User/Users.vue'),
    meta: { title: 'Users', roles: userModuleRoles },
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('@/components/User/AddOrEditUser.vue'),
    meta: { title: 'Create User', roles: userModuleRoles },
  },
  {
    path: '/users/:userId/edit',
    name: 'users.edit',
    component: () => import('@/components/User/AddOrEditUser.vue'),
    meta: { title: 'Edit User', roles: superAdminRoles },
  },
];
