<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { FormCheck } from '../Form';
import CheckRadioInput from '@/components/CheckRadioInput.vue';
import Pagination from '../Pagination/Pagination.vue';

// Types
type Header = {
  title: string;
  field: string;
  sortable?: boolean;
  align?: 'left' | 'right' | 'center';
  classList?: string | string[];
  cellClassList?: string | string[];
  type?: 'string' | 'number' | 'date';
  path?: string;
};

type RowType = Record<string, any>;

interface DataTableProps {
  // @ts-ignore
  modelValue?: RowType[];
  headers: Header[];
  data: RowType[];
  narrow?: boolean;
  paginated?: boolean;
  detailed?: boolean;
  detailHeaders?: Header[];
  perPage?: number;
  currentPage?: number;
  total?: number;
  processing?: boolean;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  showSelect?: boolean;
  singleSelect?: boolean;
  loadingMessage?: string;
  showGoToPage?: boolean;
  emptySlot?: {
    icon?: string;
    title?: string;
    description?: string;
    iconColor?: string;
    showLoader?: boolean;
    loaderWidth?: number;
  };
}

// Emits
const emit = defineEmits<{
  (e: 'pageChange', page: number): void;
  (e: 'update:modelValue', value: any[]): void;
  (e: 'sortChange', sort: { field: string; direction: 'asc' | 'desc' }): void;
}>();

// Props
const props = withDefaults(defineProps<DataTableProps>(), {
  // @ts-ignore
  modelValue: [],
  narrow: false,
  paginated: false,
  detailed: false,
  currentPage: 1,
  perPage: 10,
  total: 0,
  processing: false,
  sortField: '',
  sortDirection: 'asc',
  showSelect: false,
  singleSelect: false,
  loadingMessage: 'Loading...',
  showGoToPage: false,
  emptySlot: () => ({
    icon: 'FileText',
    title: 'No data found',
    description: 'There are no records to display.',
    loaderWidth: 40,
  }),
});

// Data Properties
const currentPageLocal = ref(props.currentPage);
const perPageLocal = ref(props.perPage);
const sortField = ref<string | null>(props.sortField);
const sortDirection = ref<'asc' | 'desc'>(props.sortDirection);
const detailedRow = ref<number | null>(null);

// Helper Functions
const getValueFromPath = (obj: RowType, header?: Header) => {
  const path = header?.path || header?.field;

  if (!path) return obj;

  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

// Computed Properties
const headersLength = computed(() => {
  let length = props.headers.length;

  if (props.detailed) length += 1;
  if (props.showSelect) length += 1;

  return length;
});

const total = computed(() => {
  return props.total || props.data.length;
});

const pageCount = computed(() => {
  return Math.ceil(total.value / perPageLocal.value);
});

const sortedData = computed(() => {
  if (!sortField.value || props.total) {
    // Remote sorting or no sorting
    return props.data;
  }

  const header = props.headers.find(h => h.field === sortField.value);
  const type = header?.type || 'string';

  const sorted = [...props.data].sort((a, b) => {
    const aValue = getValueFromPath(a, header);
    const bValue = getValueFromPath(b, header);
    return compareValues(aValue, bValue, type, sortDirection.value);
  });

  return sorted;
});

const remotePagination = computed(() => {
  return props.total;
});

const paginatedData = computed(() => {
  if (!props.paginated) {
    return sortedData.value;
  }

  // Remote pagination
  if (remotePagination.value) {
    return sortedData.value;
  } else {
    // Local pagination
    const start = (currentPageLocal.value - 1) * perPageLocal.value;
    const end = start + perPageLocal.value;
    return sortedData.value.slice(start, end);
  }
});

// Watchers
watch(
  () => props.singleSelect,
  () => {
    emit('update:modelValue', []);
  }
);

// Set intermediate state of select all checkbox
watch(
  () => props.modelValue,
  () => {
    const selectAllCheckbox = document.getElementById(
      'select-all-checkbox'
    ) as HTMLInputElement;

    if (selectAllCheckbox) {
      const totalRows = paginatedData.value.length;
      const selectedRows = props.modelValue.length;
      selectAllCheckbox.indeterminate =
        selectedRows > 0 && selectedRows < totalRows;
    }
  }
);

watch(
  () => props.data,
  () => {
    emit('update:modelValue', []);

    if (remotePagination.value) {
      // currentPageLocal.value = 1;
    }
  }
);

watch(
  () => props.currentPage,
  value => {
    currentPageLocal.value = value;
  }
);

watch(
  () => currentPageLocal.value,
  value => {
    emit('pageChange', value);
    emit('update:modelValue', []);
    detailedRow.value = null;
  }
);

watch([sortField, sortDirection], () => {
  currentPageLocal.value = 1;

  if (props.total) {
    emit('sortChange', {
      field: sortField.value as string,
      direction: sortDirection.value,
    });
  }
});

// Methods
const toggleDetailedRow = (index: number) => {
  detailedRow.value = detailedRow.value === index ? null : index;
};

const compareValues = (
  a: any,
  b: any,
  type: string,
  direction: 'asc' | 'desc'
) => {
  let result = 0;

  if (type === 'number') {
    result = a - b;
  } else if (type === 'date') {
    result = new Date(a).getTime() - new Date(b).getTime();
  } else {
    result = String(a).localeCompare(String(b));
  }

  return direction === 'asc' ? result : -result;
};

// Check if all visible rows are selected
const allVisibleRowsSelected = computed(() => {
  return (
    props.data.length > 0 &&
    props.modelValue.length === paginatedData.value.length
  );
});

const selectAllVisibleRows = (e: Event) => {
  const checked = (e.target as HTMLInputElement).checked;

  if (checked) {
    emit('update:modelValue', paginatedData.value);
  } else {
    emit('update:modelValue', []);
  }
};

const selectRow = (row: RowType) => {
  let selectedRows = [];

  if (props.singleSelect) {
    selectedRows = [row];
  } else {
    const index = props.modelValue.findIndex(r => r === row);

    if (index === -1) {
      selectedRows = [...props.modelValue, row];
    } else {
      selectedRows = props.modelValue.filter(r => r !== row);
    }
  }

  emit('update:modelValue', selectedRows);
};

const handleGoToPage = (e: Event) => {
  const page = parseInt((e.target as HTMLInputElement).value, 10);

  if (isNaN(page)) return;

  if (page < 1) {
    currentPageLocal.value = 1;
  } else if (page > pageCount.value) {
    currentPageLocal.value = pageCount.value;
  } else {
    currentPageLocal.value = page;
  }

  (e.target as HTMLInputElement).value = currentPageLocal.value.toString();
};

const handlePageChange = (page: number) => {
  currentPageLocal.value = page;
};

const getHeaderClassList = (header: Header) => {
  const classList = ['uppercase', 'whitespace-nowrap'];

  if (header.sortable) {
    classList.push('cursor-pointer underline decoration-dotted');
  }

  if (header.align === 'right') {
    classList.push('!text-right');
  }

  if (header.align === 'center') {
    classList.push('!text-center');
  }

  if (header.classList) {
    if (typeof header.classList === 'string') {
      classList.push(header.classList);
    } else if (Array.isArray(header.classList)) {
      classList.push(...header.classList);
    }
  }

  if (props.narrow) {
    classList.push('px-3 py-1');
  }

  return classList.join(' ');
};

const getDataClassList = (header: Header) => {
  const classList = ['whitespace-nowrap'];

  if (header.align === 'right') {
    classList.push('!text-right');
  }

  if (header.align === 'center') {
    classList.push('!text-center');
  }

  if (header.cellClassList) {
    if (typeof header.cellClassList === 'string') {
      classList.push(header.cellClassList);
    } else if (Array.isArray(header.cellClassList)) {
      classList.push(...header.cellClassList);
    }
  }

  if (props.narrow) {
    classList.push('px-3 py-1');
  }

  return classList.join(' ');
};

const handleSort = (header: Header) => {
  if (!header.sortable) return;

  if (sortField.value === header.field) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortField.value = header.field;
    sortDirection.value = 'asc';
  }
};

const getSortIconName = () => {
  return sortDirection.value === 'asc' ? 'ArrowUp' : 'ArrowDown';
};
</script>

<template>
  <div class="bg-white table-responsive">
    <AppTable class="table">
      <TableThead>
        <TableTr>
          <!-- Detailed Row -->
          <TableTh v-if="props.detailed" class="w-px"></TableTh>

          <!-- Select All Checkbox -->
          <TableTh v-if="props.showSelect">
            <FormCheck v-show="paginatedData.length && !props.singleSelect">
              <FormCheck.Input
                id="select-all-checkbox"
                type="checkbox"
                class="w-4 h-4 mx-1 rounded-[0.125rem]"
                :checked="allVisibleRowsSelected"
                :disabled="props.singleSelect"
                @change="selectAllVisibleRows"
              />
            </FormCheck>
          </TableTh>

          <slot name="header" :headers="headers">
            <TableTh
              v-for="header in headers"
              :key="header.title"
              :class="getHeaderClassList(header)"
              @click="handleSort(header)"
            >
              <div
                class="flex items-center gap-1"
                :class="{
                  'justify-end': header.align === 'right',
                  'justify-start': header.align === 'left',
                  'justify-center': header.align === 'center',
                }"
              >
                <span>{{ header.title }}</span>
                <Lucide
                  v-if="sortField === header.field && header.sortable"
                  :icon="getSortIconName()"
                  class="w-4 h-4"
                />
              </div>
            </TableTh>
          </slot>
        </TableTr>
      </TableThead>
      <TableTbody>
        <template v-if="!props.processing">
          <!-- Table Body -->
          <template v-if="paginatedData.length">
            <template v-for="(row, index) in paginatedData" :key="index">
              <TableTr
                :class="{ 'bg-primary/5': props.modelValue.includes(row) }"
              >
                <!-- Detailed Row -->
                <TableTd
                  v-if="props.detailed"
                  class="w-px"
                  :class="{ 'py-1': props.narrow }"
                >
                  <slot
                    name="toggle"
                    :expanded="detailedRow === index"
                    :toggle="() => toggleDetailedRow(index)"
                    :row="row"
                  >
                    <Lucide
                      :icon="
                        detailedRow === index ? 'ChevronDown' : 'ChevronRight'
                      "
                      class="cursor-pointer"
                      @click="toggleDetailedRow(index)"
                    />
                  </slot>
                </TableTd>

                <!-- Select Slot -->
                <TableTd
                  v-if="props.showSelect"
                  class="text-center w-px"
                  :class="{ 'py-1': props.narrow }"
                >
                  <slot
                    name="select"
                    :row="row"
                    :checked="props.modelValue.includes(row)"
                    :select="() => selectRow(row)"
                  >
                    <CheckRadioInput
                      class="text-primary"
                      :type="props.singleSelect ? 'radio' : 'checkbox'"
                      :checked="props.modelValue.includes(row)"
                      :size="props.singleSelect ? 24 : 22"
                      :onToggle="() => selectRow(row)"
                    />
                  </slot>
                </TableTd>

                <!-- Table Data -->
                <slot name="default" :row="row" :index="index">
                  <TableTd
                    v-for="header in headers"
                    :key="header.field"
                    :class="getDataClassList(header)"
                  >
                    <slot :name="header.field" :header="header" :row="row">
                      {{ getValueFromPath(row, header) }}
                    </slot>
                  </TableTd>
                </slot>
              </TableTr>

              <!-- Expanded Row Content -->
              <TableTr v-if="props.detailed && detailedRow === index" class="">
                <TableTd
                  :colspan="headersLength"
                  class="p-1 bg-gray-200 max-w-[1px]"
                  :class="{ 'px-3 py-1': props.narrow }"
                >
                  <slot
                    name="detailed"
                    :row="row"
                    :index="index"
                    :headers="props.detailHeaders"
                  />
                </TableTd>
              </TableTr>
            </template>
          </template>

          <!-- Empty Slot -->
          <template v-else>
            <TableTr>
              <TableTd
                :colspan="headersLength"
                class="text-center py-10 border-0"
              >
                <slot name="empty" :empty-slot="props.emptySlot">
                  <EmptyState v-bind="props.emptySlot" />
                </slot>
              </TableTd>
            </TableTr>
          </template>
        </template>

        <!-- Loader -->
        <TableTr v-else>
          <TableTd :colspan="headersLength" class="text-center py-10 border-0">
            <div class="flex justify-center">
              <LoadingIcon
                :width="props.emptySlot.loaderWidth"
                :message="props.loadingMessage"
              />
            </div>
          </TableTd>
        </TableTr>
      </TableTbody>
    </AppTable>
  </div>

  <!-- Pagination -->
  <template v-if="props.paginated && pageCount > 1">
    <div class="flex items-end">
      <slot name="pagination">
        <Pagination
          class="mt-5 px-2"
          :per-page="perPageLocal"
          :current-page="currentPageLocal"
          :page-count="pageCount"
          @page-change="handlePageChange"
        />
      </slot>

      <!-- Go to page -->
      <FormInput
        v-if="props.showGoToPage"
        id="go-to-page"
        type="number"
        :min="1"
        :max="pageCount"
        class="w-20"
        :class="{ 'w-24': pageCount > 9999 }"
        placeholder="Page"
        @keydown.enter.prevent="handleGoToPage"
        @blur="handleGoToPage"
      />
    </div>
  </template>
</template>

<style lang="scss" scoped>
.table-responsive {
  overflow-x: auto;
  border-radius: 0.25rem;
  border-left-width: 1px;
  border-right-width: 1px;

  .table {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
}
</style>
