import { superAdminRoles } from '@/defaults/roles';

export default [
  {
    path: '/services',
    name: 'tracker.services',
    component: () => import('@/pages/Tracker/Label/Labels.vue'),
    meta: { title: 'Services', roles: superAdminRoles },
  },
];
