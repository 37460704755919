<script setup lang="ts">
import {
  computed,
  type SelectHTMLAttributes,
  useAttrs,
  inject,
  ref,
} from 'vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

import FormError from './FormError.vue';
import FormLabel from './FormLabel.vue';
import type { ProvideFormInline } from './FormInline.vue';

defineOptions({ inheritAttrs: false });

interface FormSelectProps extends /* @vue-ignore */ SelectHTMLAttributes {
  value?: SelectHTMLAttributes['value'];
  modelValue?: SelectHTMLAttributes['value'];
  size?: 'sm' | 'md' | 'lg';
  label?: string;
  error?: string;
  required?: boolean;
  // Array of strings, numbers, or objects with customizable keys
  options?: Array<string | number | { [key: string]: any }>;
  emptyOptionLabel?: string;
  labelKey?: string; // Key for title, defaults to 'label'
  valueKey?: string; // Key for value, defaults to 'id'
}

interface FormSelectEmit {
  (e: 'update:modelValue', value: string): void;
}

const props = withDefaults(defineProps<FormSelectProps>(), {
  size: 'md',
  required: false,
  labelKey: 'label',
  valueKey: 'id',
});

const selectRef = ref<HTMLSelectElement>();
const attrs = useAttrs();
const formInline = inject<ProvideFormInline>('formInline', false);

const computedClass = computed(() =>
  twMerge([
    'disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50',
    '[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50',
    'transition duration-200 ease-in-out w-full border-slate-300 shadow-sm rounded-md focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50',
    props.size == 'sm' && 'text-xs py-1.5 pl-2 pr-8',
    props.size == 'md' && 'text-sm py-2 pl-3 pr-8',
    props.size == 'lg' && 'text-lg py-1.5 pl-4 pr-8',
    formInline && 'flex-1',
    typeof attrs.class === 'string' && attrs.class,
    props.error &&
      'border-red-500 focus:ring-4 focus:ring-red-500 focus:border-red-500 dark:border-red-500 dark:focus:ring-red-500',
  ])
);

const emit = defineEmits<FormSelectEmit>();

const localValue = computed({
  get() {
    if (props.modelValue === undefined && props.value === undefined) {
      const firstOption = selectRef.value?.querySelectorAll('option')[0];
      return (
        firstOption !== undefined &&
        (firstOption.getAttribute('value') !== null
          ? firstOption.getAttribute('value')
          : firstOption.text)
      );
    }

    return props.modelValue === undefined ? props.value : props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<template>
  <div>
    <FormLabel
      v-if="props.label"
      :htmlFor="attrs.id"
      :text="props.label"
      :required="props.required"
    />

    <select
      ref="selectRef"
      :class="computedClass"
      v-bind="omit(attrs, 'class')"
      v-model="localValue"
    >
      <option v-if="props.emptyOptionLabel" value="">
        {{ props.emptyOptionLabel }}
      </option>

      <!-- Default slot for options -->
      <slot :options="props.options">
        <option
          v-for="option in props.options"
          :key="typeof option === 'object' ? option[props.valueKey] : option"
          :value="typeof option === 'object' ? option[props.valueKey] : option"
        >
          {{ typeof option === 'object' ? option[props.labelKey] : option }}
        </option>
      </slot>
    </select>

    <FormError v-if="props.error" :message="props.error" />
  </div>
</template>
