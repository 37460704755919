<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { useAttrs, computed } from 'vue';

defineOptions({ inheritAttrs: false });

interface HeaderProps {
  as?: string | object;
}

const { as = 'div' } = defineProps<HeaderProps>();

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge(['p-2 font-medium', typeof attrs.class === 'string' && attrs.class])
);
</script>

<template>
  <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </component>
</template>
