export function injectRecaptchaScript(siteKey: string) {
  if (!siteKey) {
    console.error('reCAPTCHA site key is missing');
    return;
  }

  // Check if the script is already present in the DOM
  const existingScript = document.querySelector(
    `script[src*="https://www.google.com/recaptcha/api.js"]`
  );

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
}

export function removeRecaptchaScript() {
  // Recheck if the script exists in the DOM
  const existingScript = document.querySelector(
    `script[src*="https://www.google.com/recaptcha/api.js"]`
  );

  if (existingScript) {
    document.head.removeChild(existingScript);
  }
}
