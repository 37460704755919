import { parseColor } from 'tailwindcss/lib/util/color';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import dateFormats from '@/defaults/date-formats';

type DateFormatKeys = keyof typeof dateFormats;

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};

const cutText = (text: string, length: number = 50) => {
  if (typeof text !== 'string' || !text || text.length <= length) {
    return text;
  }

  return text.slice(0, length) + '...';
};

const diffTimeByNow = (time: string) => {
  const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString());
  const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString());

  const duration = dayjs.duration(endDate.diff(startDate));
  const milliseconds = Math.floor(duration.asMilliseconds());

  const days = Math.round(milliseconds / 86400000);
  const hours = Math.round((milliseconds % 86400000) / 3600000);
  let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
  const seconds = Math.round(
    (((milliseconds % 86400000) % 3600000) % 60000) / 1000
  );

  if (seconds < 30 && seconds >= 0) {
    minutes += 1;
  }

  return {
    days: days.toString().length < 2 ? '0' + days : days,
    hours: hours.toString().length < 2 ? '0' + hours : hours,
    minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
    seconds: seconds.toString().length < 2 ? '0' + seconds : seconds,
  };
};

const formatCurrency = (number: number | '', currency = 'MYR') => {
  if (!number && number !== 0) {
    return '';
  }

  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split('.');

  // Format the integer part with commas for thousands separator
  let formattedInteger = '';
  const numDigits = integerPart.length;

  for (let i = 0; i < numDigits; i++) {
    formattedInteger += integerPart[i];

    if ((numDigits - i - 1) % 3 === 0 && i < numDigits - 1) {
      formattedInteger += ',';
    }
  }

  // Combine the integer part and decimal part (if exists) with the currency symbol
  let formattedNumber = `${currency} ${formattedInteger}`;

  if (decimalPart) {
    formattedNumber += '.' + decimalPart;
  } else {
    formattedNumber += '.00';
  }

  return formattedNumber;
};

const formatDate = (
  date: string | Date,
  format: DateFormatKeys | string = 'long'
) => {
  if (!date) return date;

  const dateFormat = dateFormats[format as DateFormatKeys] || format;
  const dayjsDate = dayjs(date);

  return dayjsDate.format(dateFormat);
};

const getFormattedFileName = (fileName: string) => {
  return fileName
    .replace(/-/g, ' ')
    .replace(/\.[^/.]+$/, '')
    .replace(/\b\w/g, l => l.toUpperCase())
    .replace(/\.$/, '');
};

const getInitials = (name: string) => {
  if (!name) return '';

  const names = name.split(' ');
  const initials = names[0].charAt(0) + (names[1] ? names[1].charAt(0) : '');

  return initials.toUpperCase();
};

const isset = (obj: object | string) => {
  if (obj !== null && obj !== undefined) {
    if (typeof obj === 'object' || Array.isArray(obj)) {
      return Object.keys(obj).length;
    } else {
      return obj.toString().length;
    }
  }

  return false;
};

function obscureText(
  text: string,
  visibleCount: number = 4,
  char: string = '*',
  fromEnd: boolean = true
): string {
  // Ensure the obscuring character is a single character; default to '*' if not.
  char = char.length === 1 ? char : '*';

  // Validate input parameters to ensure proper functionality.
  if (
    visibleCount < 1 ||
    !Number.isInteger(visibleCount) ||
    !text ||
    text.length < 2
  ) {
    return text; // Return the original text if it doesn't meet criteria for obscuring.
  }

  // Adjust the visibleCount if it's greater than the text length.
  visibleCount = Math.min(visibleCount, text.length);

  if (fromEnd) {
    return char.repeat(text.length - visibleCount) + text.slice(-visibleCount);
  }

  return text.slice(0, visibleCount) + char.repeat(text.length - visibleCount);
}

const onlyNumber = (string: string) => {
  if (string) {
    return string.replace(/\D/g, '');
  } else {
    return '';
  }
};

const randomNumbers = (from: number, to: number, length: number) => {
  const numbers = [0];

  for (let i = 1; i < length; i++) {
    numbers.push(Math.ceil(Math.random() * (from - to) + to));
  }

  return numbers;
};

const simulateDelay = async (delay = 750) => {
  return await new Promise(resolve => setTimeout(resolve, delay));
};

const slideDown = (
  el: HTMLElement,
  duration = 300,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callback = (el: HTMLElement) => {}
) => {
  el.style.removeProperty('display');
  let display = window.getComputedStyle(el).display;
  if (display === 'none') display = 'block';
  el.style.display = display;
  const height = el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = '0';
  el.style.paddingTop = '0';
  el.style.paddingBottom = '0';
  el.style.marginTop = '0';
  el.style.marginBottom = '0';
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.height = height + 'px';
  el.style.removeProperty('padding-top');
  el.style.removeProperty('padding-bottom');
  el.style.removeProperty('margin-top');
  el.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    el.style.removeProperty('height');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    callback(el);
  }, duration);
};

const slideUp = (
  el: HTMLElement,
  duration = 300,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callback = (el: HTMLElement) => {}
) => {
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.height = el.offsetHeight + 'px';
  el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = '0';
  el.style.paddingTop = '0';
  el.style.paddingBottom = '0';
  el.style.marginTop = '0';
  el.style.marginBottom = '0';
  window.setTimeout(() => {
    el.style.display = 'none';
    el.style.removeProperty('height');
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    callback(el);
  }, duration);
};

const stringToHTML = (arg: string) => {
  const parser = new DOMParser(),
    DOM = parser.parseFromString(arg, 'text/html');
  return DOM.body.childNodes[0] as HTMLElement;
};

const timeAgo = (time: string) => {
  if (!time) return time;

  const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '));
  // convert to local timezone
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const diff = (new Date().getTime() - date.getTime()) / 1000; // Difference in seconds
  const dayDiff = Math.floor(diff / 86400); // Difference in days

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
    return dayjs(time).format('MMMM DD, YYYY');
  }

  return (
    (dayDiff === 0 &&
      ((diff <= 1 && 'Just now') ||
        (diff < 60 && Math.floor(diff) + ' seconds ago') ||
        (diff < 120 && '1 minute ago') ||
        (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
        (diff < 7200 && '1 hour ago') ||
        (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
    (dayDiff === 1 && 'Yesterday') ||
    (dayDiff < 7 && dayDiff + ' days ago') ||
    (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago') ||
    (dayDiff < 365 && Math.ceil(dayDiff / 30) + ' months ago') ||
    Math.ceil(dayDiff / 365) + ' years ago'
  );
};

const toKebabCase = (str: string) => {
  if (!str) return str;

  return str
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2') // Insert hyphen between lower and upper case letters.
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2') // Handle all caps followed by lowercase.
    .toLowerCase(); // Convert all to lowercase.
};

const toLocal = (date: string | Date) => {
  if (!date) return date;

  return dayjs(date).local().format();
};

const toRaw = (obj: object) => {
  return JSON.parse(JSON.stringify(obj));
};

const toRGB = (value: string) => {
  return parseColor(value).color.join(' ');
};

const toTitleCase = (str: string) => {
  if (!str) return str;

  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word?.replace(word[0], word[0]?.toUpperCase());
    })
    .join(' ');
};

const toUTC = (date: string | Date) => {
  if (!date) return date;

  return dayjs(date).utc().format();
};

const transformText = (
  text: string,
  options = {
    caseType: 'lower',
    separatorRegex: /\s/,
    separatorReplacement: '_',
    replaceRegex: /[^a-zA-Z0-9_]/,
    replaceWith: '',
  }
) => {
  if (!text || typeof text !== 'string') return text;

  const {
    caseType,
    separatorRegex,
    separatorReplacement,
    replaceRegex,
    replaceWith,
  } = options;

  const processedText = text
    .trim()
    .replace(new RegExp(separatorRegex, 'g'), separatorReplacement)
    .replace(new RegExp(replaceRegex, 'g'), replaceWith);

  if (caseType === 'title') {
    return toTitleCase(processedText);
  }

  return caseType === 'upper'
    ? processedText.toUpperCase()
    : processedText.toLowerCase();
};

export {
  capitalizeFirstLetter,
  cutText,
  diffTimeByNow,
  formatCurrency,
  formatDate,
  getFormattedFileName,
  getInitials,
  isset,
  obscureText,
  onlyNumber,
  randomNumbers,
  simulateDelay,
  slideDown,
  slideUp,
  stringToHTML,
  timeAgo,
  toKebabCase,
  toLocal,
  toRaw,
  toRGB,
  toTitleCase,
  toUTC,
  transformText,
};
