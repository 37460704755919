import { ref } from 'vue';
import * as XLSX from 'xlsx';
// @ts-ignore
import Papa from 'papaparse';

interface UseDataUploaderProps {
  onProcess: (data: any[]) => void;
}

export function useDataUploader({ onProcess }: UseDataUploaderProps) {
  const processing = ref(false);
  const file = ref<File | null>(null);

  const handleFileUpload = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files && files[0]) {
      file.value = files[0];
    }
  };

  const parseFile = () => {
    if (!file.value || processing.value) return;

    processing.value = true;

    const fileName = file.value.name.toLowerCase();

    if (fileName.endsWith('.csv')) {
      parseCSV(file.value);
    } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      parseExcel(file.value);
    } else {
      console.error('Unsupported file type');
      processing.value = false;
    }
  };

  const isEmptyRow = (row: Record<string, any>) => {
    return Object.values(row).every(value => value === null || value === '');
  };

  const parseCSV = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: (results: Papa.ParseResult<any>) => {
        const filteredData = results.data.filter(
          (row: any) => !isEmptyRow(row)
        );
        onProcess(filteredData);
        processing.value = false;
      },
      error: (error: any) => {
        console.error('Error parsing CSV:', error);
        processing.value = false;
      },
    });
  };

  const parseExcel = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const binaryStr = event.target?.result as string;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Assume the data is in the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert the sheet to CSV
      const csv = XLSX.utils.sheet_to_csv(worksheet);

      // Parse the CSV with PapaParse
      Papa.parse(csv, {
        header: true,
        complete: (results: Papa.ParseResult<any>) => {
          const filteredData = results.data.filter(
            (row: any) => !isEmptyRow(row)
          );
          onProcess(filteredData);
          processing.value = false;
        },
        error: (error: any) => {
          console.error('Error parsing CSV:', error);
          processing.value = false;
        },
      });
    };

    reader.onerror = (error: ProgressEvent<FileReader>) => {
      console.error('Error reading Excel file:', error);
      processing.value = false;
    };

    reader.readAsBinaryString(file);
  };

  return {
    processing,
    file,
    handleFileUpload,
    parseFile,
  };
}
