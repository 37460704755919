import { defineStore } from 'pinia';
import { ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import { defaultGeneralSettingsForm } from '@/defaults/settings/general-settings';
import { ResponseStatus } from '@/consts';
import axios from '@/services/axios';
import type { GeneralSettings } from '@/types/settings/general-settings';

export const useSettingStore = defineStore('setting', () => {
  const settings = ref<GeneralSettings>(cloneDeep(defaultGeneralSettingsForm));

  // Actions
  const getGeneralSettings = async (): Promise<any> => {
    const response = await axios.get('/v1/settings/general');

    if (response?.data?.status === ResponseStatus.Success) {
      settings.value = response.data.data;
    }

    return response;
  };

  const updateGeneralSettings = async (
    payload: GeneralSettings
  ): Promise<any> => {
    return await axios.post('/v1/settings/general', payload);
  };

  return {
    settings,

    // Actions
    getGeneralSettings,
    updateGeneralSettings,
  };
});
