<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { Menu as HeadlessMenu } from '@headlessui/vue';
import { useAttrs, computed } from 'vue';

defineOptions({ inheritAttrs: false });

interface MenuProps
  extends /* @vue-ignore */ ExtractProps<typeof HeadlessMenu> {
  as?: string | object;
}

const { as = 'div' } = defineProps<MenuProps>();

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge(['relative', typeof attrs.class === 'string' && attrs.class])
);
</script>

<template>
  <HeadlessMenu as="template">
    <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
      <slot></slot>
    </component>
  </HeadlessMenu>
</template>
