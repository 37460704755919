import { App } from 'vue';

// Dialog
import Dialog from './components/Base/Headless/Dialog/Dialog.vue';
import DialogTitle from './components/Base/Headless/Dialog/Title.vue';
import DialogDescription from './components/Base/Headless/Dialog/Description.vue';
import DialogPanel from './components/Base/Headless/Dialog/Panel.vue';
import DialogFooter from './components/Base/Headless/Dialog/Footer.vue';

// Menu
import Menu from './components/Base/Headless/Menu/Menu.vue';
import MenuButton from './components/Base/Headless/Menu/Button.vue';
import MenuItems from './components/Base/Headless/Menu/Items.vue';
import MenuItem from './components/Base/Headless/Menu/Item.vue';
import MenuDivider from './components/Base/Headless/Menu/Divider.vue';
import MenuHeader from './components/Base/Headless/Menu/Header.vue';
import MenuFooter from './components/Base/Headless/Menu/Footer.vue';

// Data Table
import DataTable from './components/Base/DataTable/DataTable.vue';

// Table
import Table from './components/Base/Table/Table.vue';
import TableThead from './components/Base/Table/Thead.vue';
import TableTbody from './components/Base/Table/Tbody.vue';
import TableTr from './components/Base/Table/Tr.vue';
import TableTh from './components/Base/Table/Th.vue';
import TableTd from './components/Base/Table/Td.vue';

// Misc
import Alert from './components/Base/Alert';
import Breadcrumb from './components/Base/Breadcrumb';
import Button from './components/Base/Button';
import * as Form from './components/Base/Form';
import ImageZoom from './components/Base/ImageZoom';
import Lucide from './components/Base/Lucide';
import LoadingIcon from './components/Base/LoadingIcon';
import Notification from './components/Base/Notification';
import TinySlider from './components/Base/TinySlider';
import Tippy from './components/Base/Tippy';
import TippyContent from './components/Base/TippyContent';
import TomSelect from './components/Base/TomSelect';

// Components
import { TooltipHeader } from './components/Tooltip';
import { UserCard } from './components/User';
import Badge from './components/Base/Badge';
import ClickToCopy from './components/Base/ClickToCopy';
import DataUploader from './components/Base/DataUploader';
import EmptyState from './components/Base/EmptyState';
import SectionHeader from './components/Base/SectionHeader';

const componentPrefix = import.meta?.env?.VITE_COMPONENT_PREFIX || 'App';

export default (app: App<Element>) => {
  // Dialog components
  app.component(`${componentPrefix}Dialog`, Dialog);
  app.component('DialogTitle', DialogTitle);
  app.component('DialogDescription', DialogDescription);
  app.component('DialogPanel', DialogPanel);
  app.component('DialogFooter', DialogFooter);

  // Form components
  app.component('FormInput', Form.FormInput);
  app.component('FormPassword', Form.FormPassword);
  app.component('FormError', Form.FormError);
  app.component('FormTextarea', Form.FormTextarea);
  app.component('FormHelp', Form.FormHelp);
  app.component('FormInline', Form.FormInline);
  app.component('FormLabel', Form.FormLabel);
  app.component('FormSelect', Form.FormSelect);
  app.component('FormSwitch', Form.FormSwitch);
  app.component('FormSwitchInput', Form.FormSwitch.Input);
  app.component('FormSwitchLabel', Form.FormSwitch.Label);
  app.component('FormInputGroup', Form.InputGroup);

  // Menu components
  app.component(`${componentPrefix}Menu`, Menu);
  app.component('MenuButton', MenuButton);
  app.component('MenuItems', MenuItems);
  app.component('MenuItem', MenuItem);
  app.component('MenuDivider', MenuDivider);
  app.component('MenuHeader', MenuHeader);
  app.component('MenuFooter', MenuFooter);

  // Table components
  app.component(`${componentPrefix}Table`, Table);
  app.component('TableThead', TableThead);
  app.component('TableTbody', TableTbody);
  app.component('TableTr', TableTr);
  app.component('TableTh', TableTh);
  app.component('TableTd', TableTd);

  // Misc components
  app.component(`${componentPrefix}Alert`, Alert);
  app.component(`${componentPrefix}Button`, Button);
  app.component('Breadcrumb', Breadcrumb);
  app.component('ImageZoom', ImageZoom);
  app.component('Lucide', Lucide);
  app.component('Notification', Notification);
  app.component('LoadingIcon', LoadingIcon);
  app.component('Tippy', Tippy);
  app.component('TinySlider', TinySlider);
  app.component('TippyContent', TippyContent);
  app.component('TomSelect', TomSelect);

  // Custom components
  app.component(`${componentPrefix}Badge`, Badge);
  app.component('ClickToCopy', ClickToCopy);
  app.component('DataUploader', DataUploader);
  app.component('EmptyState', EmptyState);
  app.component('SectionHeader', SectionHeader);
  app.component('TooltipHeader', TooltipHeader);
  app.component('UserCard', UserCard);
  app.component(`${componentPrefix}DataTable`, DataTable);
};
