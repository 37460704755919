<script setup lang="ts">
import { computed, type ButtonHTMLAttributes, useAttrs } from 'vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

defineOptions({ inheritAttrs: false });

interface DismissButtonProps extends /* @vue-ignore */ ButtonHTMLAttributes {
  as?: string | object;
}

const { as = 'button' } = defineProps<DismissButtonProps>();

const attrs = useAttrs();

const computedClass = computed(() =>
  twMerge([
    'text-slate-800 py-2 px-3 absolute right-0 my-auto mr-2',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <component
    :is="as"
    type="button"
    aria-label="Close"
    :class="computedClass"
    v-bind="omit(attrs, 'class')"
  >
    <slot></slot>
  </component>
</template>
