<script setup lang="ts">
import { useSlots } from 'vue';
import { type Props } from 'tippy.js';

const slots = useSlots();

const { title, content } = defineProps<{
  title?: string;
  content: any;
  options?: Partial<Props>;
}>();

const copyText = async () => {
  if (!content) return;

  try {
    await navigator.clipboard.writeText(content);
  } catch (err) {
    // Handle errors here
  }
};
</script>

<template>
  <Tippy
    :content="title || 'Click to Copy'"
    @click="copyText()"
    :options="options"
  >
    <slot v-if="slots.default"></slot>
    <Lucide v-else icon="Copy" class="inline-block w-4 h-4 ml-1" />
  </Tippy>
</template>
