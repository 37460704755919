import axios, { type AxiosResponse, type AxiosError } from 'axios';

import { NotificationType } from '@/consts';
import { useAuthStore } from '@/stores/auth';
import { useCommonStore } from '@/stores/common';
import router from '@/router';

const headers: any = {
  Accept: 'application/json',
  'Content-type': 'application/json',
};

const env = import.meta.env;
axios.get(`${env.VITE_API_BASE_URL}/sanctum/csrf-cookie`);

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers,
  withCredentials: true,
});

// Request interceptor
httpClient.interceptors.request.use(
  config => {
    // Get accessToken from localStorage
    const token = localStorage.getItem('accessToken');

    // If accessToken exists, add it to the headers
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    // Bypass 401 handling for the logout route
    // This is to prevent infinite loop when the accessToken is expired
    if (error.config && error.config?.url?.includes('logout')) {
      useAuthStore().resetSession();
      router.push({ name: 'login' });
      return Promise.reject(error);
    }

    const { setNotification } = useCommonStore();

    if (error.response && error.response.status === 401) {
      if (router.currentRoute.value.name !== 'login') {
        await useAuthStore().logout();
        // setNotification({
        //   title: 'Session expired',
        //   message: 'Your session has expired. Please login again.',
        //   type: NotificationType.Danger,
        // });
      }
    }

    if (error.response && error.response.status === 403) {
      router.push({ name: 'home' });
      setNotification({
        title: 'Unauthorized',
        message: 'You are not authorized to access this page.',
        type: NotificationType.Danger,
      });
    }

    // if (error.response && error.response.status === 404) {
    //   setNotification({
    //     title: 'Not found',
    //     message: 'The resource you are looking for does not exist.',
    //     type: NotificationType.Danger,
    //   });
    // }

    if (error.response && error.response.status === 429) {
      setNotification({
        title: 'Too many requests',
        message:
          'You have exceeded the number of requests allowed. Please try again later.',
        type: NotificationType.Danger,
      });
    }

    if (error.response && error.response.status === 500) {
      // setNotification({
      //   title: 'Server error',
      //   message: 'Something went wrong. Please try again later.',
      //   type: NotificationType.Danger,
      // });
    }

    if (error.response && error.response.status === 503) {
      setNotification({
        title: 'Service unavailable',
        message:
          'The service is temporarily unavailable. Please try again later.',
        type: NotificationType.Danger,
      });
    }

    return Promise.reject(error);
  }
);

export default httpClient;
