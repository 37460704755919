<script setup lang="ts">
import { computed } from 'vue';

import { useThemeStore } from '@/stores/theme';

// Stores
const themeStore = useThemeStore();

const currentLayout = computed(() => themeStore.theme.layout);

// Methods
const toggleLayout = () => {
  const layout =
    currentLayout.value === 'side-menu' ? 'simple-menu' : 'side-menu';
  useThemeStore().setLayout(layout);
};
</script>

<template>
  <div class="hidden xl:block z-50">
    <button
      type="button"
      class="absolute left-[244px] top-10 flex items-center justify-center text-white rounded cursor-pointer bg-white/10"
      @click.prevent="toggleLayout()"
    >
      <Lucide
        :icon="currentLayout === 'side-menu' ? 'ChevronLeft' : 'ChevronRight'"
        class="w-7 h-7"
      />
    </button>
  </div>
</template>
