<script setup lang="ts">
import { computed, provide, ref, watch } from 'vue';
import { RouterView } from 'vue-router';

import { NotificationType } from '@/consts';
import { useCommonStore } from '@/stores/common';
import type { NotificationElement } from './components/Base/Notification';
import type { NotificationObject } from '@/types/common';

// stores
const useCommon = useCommonStore();

// Data properties
const notificationRef = ref<NotificationElement>();
const notificationTypes = {
  [NotificationType.Success]: {
    icon: 'CheckCircle',
    textClass: 'text-green-500',
  },
  [NotificationType.Info]: {
    icon: 'Info',
    textClass: 'text-blue-500',
  },
  [NotificationType.Warning]: {
    icon: 'AlertCircle',
    textClass: 'text-yellow-500',
  },
  [NotificationType.Danger]: {
    icon: 'XCircle',
    textClass: 'text-red-500',
  },
};

provide('bind[notification]', (el: NotificationElement) => {
  notificationRef.value = el;
});

const notification = computed<NotificationObject>(() => {
  return useCommon.notification;
});

const notificationType = computed(() => {
  // Ensure the notification type is one of the keys of notificationTypes
  const typeKey = (notification.value.type ||
    NotificationType.Success) as keyof typeof notificationTypes;

  return notificationTypes[typeKey];
});

// Watchers
watch(
  () => notification.value,
  value => {
    if (value.title || value.message) {
      notificationRef.value?.showToast();
    }
  }
);
</script>

<template>
  <RouterView />

  <Notification refKey="notification" class="flex">
    <Lucide :icon="notificationType.icon" :class="notificationType.textClass" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ notification.title }}</div>
      <div
        v-if="notification.message"
        class="text-slate-500"
        :class="{ 'mt-1': notification.title }"
      >
        {{ notification.message }}
      </div>
    </div>
  </Notification>
</template>
