<script setup lang="ts">
import { ref } from 'vue';

import { useDataUploader } from '@/composables/useDataUploader';

const props = defineProps({
  title: {
    type: String,
    default: 'Upload Document',
  },
  label: {
    type: String,
    default: 'Upload CSV or Excel Document',
  },
});

const emit = defineEmits({
  close: () => true,
  processed: (data: any[]) => true,
});

// Data Properties
const modelButtonRef = ref(null);

// Methods
const closeDialog = () => emit('close');

const { processing, file, handleFileUpload, parseFile } = useDataUploader({
  onProcess: (data: any[]) => {
    emit('processed', data);
    emit('close');
  },
});
</script>

<template>
  <AppDialog :open="true" :initialFocus="modelButtonRef" @close="closeDialog()">
    <DialogPanel>
      <DialogTitle class="text-lg font-medium text-gray-900">
        {{ props.title }}
      </DialogTitle>

      <form
        @submit.prevent="parseFile"
        class="p-5 md:p-6 space-y-4 md:space-y-6"
      >
        <slot></slot>

        <div>
          <FormLabel htmlFor="file">{{ props.label }}</FormLabel>
          <FormInput
            ref="modelButtonRef"
            id="file"
            type="file"
            accept=".csv, .xls, .xlsx"
            required
            class="form-input"
            :disabled="processing"
            @change="handleFileUpload"
          />
        </div>

        <div class="text-end">
          <AppButton
            variant="outline-secondary"
            type="button"
            class="w-24 mr-4"
            :disabled="processing"
            @click.prevent="closeDialog"
          >
            Cancel
          </AppButton>

          <AppButton
            variant="primary"
            type="submit"
            class="w-24"
            :disabled="processing"
          >
            Upload
          </AppButton>
        </div>
      </form>
    </DialogPanel>
  </AppDialog>
</template>
