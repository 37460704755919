<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import tippy, {
  type PopperElement,
  type Props,
  roundArrow,
  animateFill as animateFillPlugin,
} from 'tippy.js';
import '@/assets/css/vendors/tippy.css';

export type ProvideTippy = (el: PopperElement) => void;

interface TippyContentProps {
  to: string;
  refKey?: string;
  options?: Partial<Props>;
}

const props = defineProps<TippyContentProps>();

const tippyRef = ref<PopperElement>();
const tippyInstance = ref<any>(null);

const init = (el: PopperElement, props: TippyContentProps) => {
  tippyInstance.value = tippy(`[data-tooltip="${props.to}"]`, {
    plugins: [animateFillPlugin],
    content: el,
    allowHTML: true,
    arrow: roundArrow,
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
          },
        },
      ],
    },
    animateFill: false,
    animation: 'shift-away',
    theme: 'light',
    trigger: 'click',
    ...props.options,
  });
};

const bindInstance = (el: PopperElement) => {
  if (props.refKey) {
    const bind = inject<ProvideTippy>(`bind[${props.refKey}]`);
    if (bind) {
      bind(el);
    }
  }
};

onMounted(() => {
  if (tippyRef.value) {
    init(tippyRef.value, props);
    bindInstance(tippyRef.value);
  }
});

const showTooltip = () => {
  tippyInstance.value?.[0]?.show();
};

const hideTooltip = () => {
  tippyInstance.value?.[0]?.hide();
};

defineExpose({ showTooltip, hideTooltip });
</script>

<template>
  <div ref="tippyRef">
    <slot></slot>
  </div>
</template>
