import { adminRoles } from '@/defaults/roles';

export default [
  {
    path: '/options',
    name: 'options',
    component: () => import('@/pages/Option/Options.vue'),
    meta: { roles: adminRoles },
  },
];
