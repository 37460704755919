<script setup lang="ts">
import { computed, useAttrs, provide } from 'vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

defineOptions({ inheritAttrs: false });

export type ProvideFormInline = boolean;

const attrs = useAttrs();

const computedClass = computed(() =>
  twMerge([
    'block sm:flex items-center',
    typeof attrs.class === 'string' && attrs.class,
  ])
);

provide<ProvideFormInline>('formInline', true);
</script>

<template>
  <div :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </div>
</template>
