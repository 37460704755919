import { superAdminRoles } from '@/defaults/roles';

export default [
  {
    path: '/stages',
    name: 'tracker.stages',
    component: () => import('@/pages/Tracker/Stage/Stages.vue'),
    meta: { title: 'Stages', roles: superAdminRoles },
  },
];
