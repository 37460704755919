import { superAdminRoles } from '@/defaults/roles';

export default [
  {
    path: '/call-slots',
    name: 'call-slots',
    component: () => import('@/pages/CallSlot/CallSlots.vue'),
    meta: { title: 'Call Slots', roles: superAdminRoles },
  },
];
