<script setup lang="ts">
import { computed } from 'vue';

import { superAdminRoles } from '@/defaults/roles';
import { useAuthStore } from '@/stores/auth';

// Stores
const authStore = useAuthStore();

// Data properties
const menu = [
  {
    title: 'My Profile',
    icon: 'User',
    route: { name: 'profile' },
  },
  {
    title: 'Change Password',
    icon: 'Lock',
    route: { name: 'change-password' },
  },
  {
    title: 'Site Settings',
    icon: 'Settings',
    route: { name: 'site-settings' },
    meta: { roles: superAdminRoles },
  },
];

// Computed properties
const currentUser = computed(() => authStore.user);
const currentUserRoles = computed(() => authStore.roles);

const filteredMenu = computed(() => {
  // Filter the menu based on the user role
  return menu.filter(menuItem => {
    const menuRoles = menuItem.meta?.roles || [];

    if (!menuRoles.length) return true;

    return menuRoles.some(role => {
      return currentUserRoles.value?.includes(role);
    });
  });
});
</script>

<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div
      class="flex flex-col-reverse col-span-12 lg:col-span-4 2xl:col-span-3 lg:block"
    >
      <div class="mt-5 intro-y box lg:mt-0">
        <!-- User Profile -->
        <div v-if="currentUser" class="relative flex items-center p-5">
          <div class="w-12 h-12 image-fit">
            <img
              v-if="currentUser.avatar"
              :src="currentUser.avatar"
              class="rounded-full"
              alt="User Profile"
            />
            <span
              v-else
              class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-slate-200"
            >
              <span class="text-xl font-medium leading-none text-gray-900">
                {{ currentUser.initials }}
              </span>
            </span>
          </div>

          <div class="ml-4 mr-auto">
            <div class="text-base font-medium">
              {{ currentUser.first_name }} {{ currentUser.last_name }}
            </div>
            <div class="text-slate-500">{{ currentUser.email }}</div>
          </div>
        </div>

        <!-- Navigation -->
        <div
          class="p-5 space-y-5 border-t border-slate-200/60 dark:border-darkmode-400"
        >
          <RouterLink
            v-for="item in filteredMenu"
            :key="item.title"
            :to="item.route"
            class="flex items-center"
            exact-active-class="active"
          >
            <Lucide :icon="item.icon" class="w-4 h-4 mr-2" />
            {{ item.title }}
          </RouterLink>
        </div>

        <!-- Logout -->
        <div class="p-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <a
            href="#"
            class="flex items-center text-danger"
            @click.prevent="authStore.logout()"
          >
            <Lucide icon="LogOut" class="w-4 h-4 mr-2" />
            Logout
          </a>
        </div>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
      <RouterView />
    </div>
  </div>
</template>

<style scoped>
.active {
  @apply font-medium text-primary;
}
</style>
