import { AuthLayout } from '@/themes';

export default {
  path: '/',
  component: AuthLayout,
  meta: { guest: true },
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/Auth/Login.vue'),
      meta: {
        title: 'Login',
        requiresAuth: false,
        redirectIfAuth: true,
        requiresRecaptcha: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/pages/Auth/Register.vue'),
      meta: {
        title: 'Customer Registration',
        requiresAuth: false,
        redirectIfAuth: true,
        requiresRecaptcha: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/pages/Auth/ForgotPassword.vue'),
      meta: {
        title: 'Forgot Password',
        requiresAuth: false,
        redirectIfAuth: true,
        requiresRecaptcha: true,
      },
    },
    {
      path: '/email-verification',
      name: 'email-verification',
      component: () => import('@/pages/Auth/EmailVerification.vue'),
      meta: {
        title: 'Email Verification',
        requiresAuth: false,
        redirectIfAuth: true,
      },
    },
  ],
};
