import { UserRole } from '@/consts';

export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/pages/Role/Roles.vue'),
    meta: { title: 'Roles', roles: [UserRole.Developer] },
  },
];
