export enum Setting {
  // Employee Login
  IpRestrictionEnabled = 'ip_restriction_enabled',
  WhitelistedIps = 'whitelisted_ips',

  // Fiscal Year
  BeginningFiscalYear = 'beginning_fiscal_year',
  CurrentFiscalYear = 'current_fiscal_year',

  // Tracker
  TrackerRefreshInterval = 'tracker_refresh_interval',
}
