<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { superAdminRoles } from '@/defaults/roles';
import { useAuthStore } from '@/stores/auth';

const router = useRouter();

// Stores
const authStore = useAuthStore();
const { hasAnyRole } = authStore;

// Computed properties
const currentUser = computed(() => authStore.user);
</script>

<template>
  <AppMenu v-if="currentUser" class="block ml-auto">
    <MenuButton
      class="w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x"
    >
      <span
        class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-slate-200"
      >
        <span class="text-sm font-medium leading-none text-gray-900">
          {{ currentUser.initials }}
        </span>
      </span>
    </MenuButton>

    <MenuItems
      class="w-56 mt-px relative bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white"
    >
      <MenuHeader class="font-normal">
        <div class="font-medium">
          {{ currentUser.first_name }} {{ currentUser.last_name }}
        </div>
        <div class="text-xs text-white/70 mt-0.5 dark:text-slate-500">
          {{ currentUser.email }}
        </div>
      </MenuHeader>

      <MenuDivider class="bg-white/[0.08]" />

      <ClickToCopy
        v-if="currentUser.referral_code"
        title="Copy Referral Code"
        :content="currentUser.referral_code"
        :options="{ theme: 'light' }"
      >
        <MenuItem
          class="hover:bg-white/5 flex items-center justify-between gap-2"
        >
          <div class="flex flex-col">
            <span class="text-xs text-white/70">Your Referral Code</span>
            <div class="text-base font-medium">
              {{ currentUser.referral_code }}
            </div>
          </div>

          <div
            class="flex items-center justify-center bg-primary p-2 rounded-full shadow-sm shadow-white/30"
          >
            <Lucide icon="Gift" class="w-5 h-5 text-yellow-400" />
          </div>
        </MenuItem>
      </ClickToCopy>

      <MenuDivider class="bg-white/[0.08]" />

      <MenuItem
        class="hover:bg-white/5"
        @click="router.push({ name: 'profile' })"
      >
        <Lucide icon="User" class="w-4 h-4 mr-2" />
        Profile
      </MenuItem>

      <MenuItem
        class="hover:bg-white/5"
        @click="router.push({ name: 'change-password' })"
      >
        <Lucide icon="Lock" class="w-4 h-4 mr-2" />
        Change Password
      </MenuItem>

      <MenuItem
        v-if="hasAnyRole(superAdminRoles)"
        class="hover:bg-white/5"
        @click="router.push({ name: 'site-settings' })"
      >
        <Lucide icon="Settings" class="w-4 h-4 mr-2" />
        Site Settings
      </MenuItem>

      <MenuDivider class="bg-white/[0.08]" />

      <MenuItem class="hover:bg-white/5" @click="authStore.logout()">
        <Lucide icon="LogOut" class="w-4 h-4 mr-2" />
        Logout
      </MenuItem>
    </MenuItems>
  </AppMenu>
</template>
