import { adminRoles } from '@/defaults/roles';

export default [
  {
    path: '/tax-document-types',
    name: 'tax-document-types',
    component: () => import('@/pages/TaxDocumentType/TaxDocumentTypes.vue'),
    meta: { roles: adminRoles },
  },
];
