<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { computed, useAttrs, provide } from 'vue';

defineOptions({ inheritAttrs: false });

export type ProvideInputGroup = boolean;

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge(['flex', typeof attrs.class === 'string' && attrs.class])
);

provide<ProvideInputGroup>('inputGroup', true);
</script>

<template>
  <div :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </div>
</template>
