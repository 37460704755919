import { dmsRoles } from '@/defaults/roles';

export default [
  {
    path: '/dms',
    name: 'dms',
    component: () => import('@/pages/DMS/DMS.vue'),
    meta: { title: 'DMS', roles: dmsRoles },
  },
  {
    path: '/dms/documents/:documentId',
    name: 'dms.document.view',
    component: () => import('@/pages/DMS/DocumentDetails.vue'),
    meta: { title: 'Document Details', roles: dmsRoles },
  },
  {
    path: '/dms/documents/:documentId/preview',
    name: 'dms.document.preview',
    component: () => import('@/pages/DMS/DocumentPreview.vue'),
    meta: { title: 'Document Preview', roles: dmsRoles },
  },
];
