import { trackerRoles } from '@/defaults/roles';
import labelRoutes from './label';
import stageRoutes from './stage';

export default [
  {
    path: '/tracker',
    name: 'tracker',
    component: () => import('@/pages/Tracker/TrackerBoard.vue'),
    meta: { title: 'Tracker', roles: trackerRoles },
  },
  ...labelRoutes,
  ...stageRoutes,
];
