<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { MenuItem as HeadlessMenuItem } from '@headlessui/vue';
import { useAttrs, computed } from 'vue';

defineOptions({ inheritAttrs: false });

interface ItemProps
  extends /* @vue-ignore */ ExtractProps<typeof HeadlessMenuItem> {
  as?: string | object;
}

const { as = 'a' } = defineProps<ItemProps>();

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge([
    'cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <HeadlessMenuItem as="template">
    <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
      <slot></slot>
    </component>
  </HeadlessMenuItem>
</template>
