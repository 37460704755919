import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores/auth';
import { UserRole } from '@/consts';
import sideMenu from '@/main/side-menu';
import type { Icon } from '@/components/Base/Lucide/Lucide.vue';

export type MenuItemMeta = {
  roles: UserRole[];
};

export interface Menu {
  icon: Icon;
  title: string;
  pageName?: string;
  subMenu?: Menu[];
  ignore?: boolean;
  meta?: MenuItemMeta;
}

export interface MenuState {
  menuValue: Array<Menu | 'divider'>;
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    menuValue: [],
  }),
  getters: {
    menu() {
      const authStore = useAuthStore();
      const currentUserRoles = authStore.roles;

      return sideMenu.filter(menu => {
        if (!currentUserRoles?.length || menu === 'divider') return true;

        const menuRoles = menu.meta?.roles || [];

        if (menuRoles.length) {
          // TODO: Check for sub menu as well
          return menuRoles.some(role => {
            return currentUserRoles?.includes(role);
          });
        }

        return true;
      });
    },
  },
});
