import { allRoles, superAdminRoles } from '@/defaults/roles';
import { AccountLayout } from '@/themes';

export default {
  path: '/account',
  component: AccountLayout,
  children: [
    {
      path: 'profile',
      name: 'profile',
      component: () => import('@/pages/Account/Profile.vue'),
      meta: { title: 'My Profile', roles: allRoles },
    },
    {
      path: 'change-password',
      name: 'change-password',
      component: () => import('@/pages/Account/ChangePassword.vue'),
      meta: { title: 'Change Password', roles: allRoles },
    },
    {
      path: 'site-settings',
      name: 'site-settings',
      component: () => import('@/pages/Account/SiteSettings.vue'),
      meta: { title: 'Website Settings', roles: superAdminRoles },
    },
  ],
};
