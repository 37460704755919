import { createRouter, createWebHistory } from 'vue-router';
import { useHead } from 'unhead';

import {
  injectRecaptchaScript,
  removeRecaptchaScript,
} from '@/utils/recaptcha';
import { useAuthStore } from '@/stores/auth';
import { UserRole } from '@/consts';
import { useSettingStore } from '@/stores/setting';
import routes from './routes';

const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const appName = import.meta?.env?.VITE_APP_NAME || 'File The Tax';
  const { meta } = to;

  useHead({ title: meta.title ? meta.title : appName });

  // Inject or remove reCAPTCHA script based on the route meta
  import.meta.env.VITE_RECAPTCHA_ENABLED === 'true' && to.meta.requiresRecaptcha
    ? injectRecaptchaScript(recaptchaSiteKey)
    : removeRecaptchaScript();

  const authStore = useAuthStore();
  const settingsStore = useSettingStore();

  try {
    if (authStore.user && !settingsStore.settings) {
      await settingsStore.getGeneralSettings();
    }

    if (!authStore.user) {
      await authStore.getCurrentUser();
    }
  } catch (error: any) {
    console.log(error);
  }

  const { isAuthenticated, hasAnyRole } = authStore;

  // Assume auth is required unless explicitly stated otherwise
  const requiresAuth = to.meta.requiresAuth !== false;
  const redirectIfAuth = to.meta.redirectIfAuth === true;

  // Check if the route requires any roles
  const requiredRoles = to.meta?.roles as UserRole[] | [];

  if (!isAuthenticated && requiresAuth) {
    next({ name: 'login' });
  } else if (isAuthenticated && redirectIfAuth) {
    // Redirect authenticated users away from auth pages
    next({ name: 'dashboard' });
  } else if (requiredRoles && !hasAnyRole(requiredRoles)) {
    console.log('User does not have the required roles', requiredRoles);
    // Redirect users without the required roles
    next({ name: 'not-found' });
  } else {
    next();
  }
});

export default router;
