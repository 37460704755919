<script setup lang="ts">
import { computed } from 'vue';
import { twMerge } from 'tailwind-merge';

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const props = withDefaults(defineProps<SectionHeaderProps>(), { size: 'md' });

const titleClassList = computed(() =>
  twMerge(
    'font-medium',
    props.size === 'xs' && 'text-xs',
    props.size === 'sm' && 'text-base',
    props.size === 'md' && 'text-lg',
    props.size === 'lg' && 'text-xl'
  )
);
</script>

<template>
  <div>
    <h2 :class="titleClassList" v-html="props.title"></h2>
    <p
      v-if="props.subtitle"
      v-html="props.subtitle"
      class="text-base text-gray-500"
    ></p>
  </div>
</template>
