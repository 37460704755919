<script setup lang="ts">
import {
  computed,
  type InputHTMLAttributes,
  ref,
  useAttrs,
  inject,
  onMounted,
  watch,
} from 'vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

import { type ProvideFormInline } from './FormInline.vue';
import { type ProvideInputGroup } from './InputGroup/InputGroup.vue';

import FormError from './FormError.vue';
import FormLabel from './FormLabel.vue';

defineOptions({ inheritAttrs: false });

// Types
interface FormTextareaProps extends /* @vue-ignore */ InputHTMLAttributes {
  value?: InputHTMLAttributes['value'];
  modelValue?: InputHTMLAttributes['value'];
  formTextareaSize?: 'sm' | 'lg';
  label?: string;
  rounded?: boolean;
  error?: string;
  required?: boolean;
  autoGrow?: boolean;
  maxHeight?: number;
}

interface FormTextareaEmit {
  (e: 'update:modelValue', value: string): void;
}

// Composables
const attrs = useAttrs();

// Props
const props = defineProps<FormTextareaProps>();

// Injections
const formInline = inject<ProvideFormInline>('formInline', false);
const inputGroup = inject<ProvideInputGroup>('inputGroup', false);

// Emits
const emit = defineEmits<FormTextareaEmit>();

// Data Properties
const textareaRef = ref<HTMLTextAreaElement | null>(null);

// Computed Properties
const computedClass = computed(() =>
  twMerge([
    'disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent',
    '[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent',
    'transition duration-200 ease-in-out w-full text-sm border-slate-300 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80',
    props.formTextareaSize == 'sm' && 'text-xs py-1.5 px-2',
    props.formTextareaSize == 'lg' && 'text-lg py-1.5 px-4',
    props.rounded && 'rounded-full',
    formInline && 'flex-1',
    inputGroup &&
      'rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r z-10',
    typeof attrs.class === 'string' && attrs.class,
    props.error &&
      'border-red-500 focus:ring-4 focus:ring-red-500 focus:border-red-500 dark:border-red-500 dark:focus:ring-red-500',
    props.autoGrow && 'overflow-hidden min-h-10 overflow-y-auto resize-none',
  ])
);

const localValue = computed({
  get() {
    return props.modelValue === undefined ? props.value : props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

// Watch for changes in the text value and adjust height accordingly
watch(localValue, () => {
  if (props.autoGrow) adjustHeight();
});

// Hooks
onMounted(() => {
  // Automatically adjust the height on mount and when the text changes
  adjustHeight();
});

// Methods

// Function to adjust the height of the textarea
const adjustHeight = () => {
  if (!textareaRef.value) return;

  // Reset height to auto to recalculate
  textareaRef.value.style.height = 'auto';

  // Set new height based on scrollHeight, but cap it at a max height
  textareaRef.value.style.height =
    Math.min(textareaRef.value.scrollHeight, props.maxHeight || 100) + 'px';
};
</script>

<template>
  <div>
    <FormLabel
      v-if="props.label"
      :htmlFor="attrs.id"
      :text="props.label"
      :required="props.required"
    />

    <textarea
      ref="textareaRef"
      :type="props.type"
      :class="computedClass"
      v-bind="omit(attrs, 'class')"
      v-model="localValue"
    />

    <FormError v-if="props.error" :message="props.error" />
  </div>
</template>
