<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { computed, useAttrs, inject } from 'vue';
import type { ProvideFormInline } from './FormInline.vue';

defineOptions({ inheritAttrs: false });

const attrs = useAttrs();

const props = defineProps<{
  text?: string;
  required?: boolean;
}>();
const formInline = inject<ProvideFormInline>('formInline', false);

const computedClass = computed(() =>
  twMerge([
    'inline-block mb-1 text-gray-900',
    formInline && 'mb-1 sm:mb-0 sm:mr-5 sm:text-right',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <label :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot>
      <!-- Do not wrap the * to a new line -->
      {{ props.text
      }}<span v-if="props.required" class="text-red-500 ml-0.5">*</span>
    </slot>
  </label>
</template>
