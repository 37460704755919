<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { computed, type LiHTMLAttributes, useAttrs } from 'vue';
import Button from '../Button';

defineOptions({ inheritAttrs: false });

interface LinkProps extends /* @vue-ignore */ LiHTMLAttributes {
  as?: string | object;
  active?: boolean;
}

const { as = 'a', active = false } = defineProps<LinkProps>();

const attrs = useAttrs();

const computedClass = computed(() =>
  twMerge([
    'min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3',
    active && '!box font-medium dark:bg-darkmode-400',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <li>
    <Button :as="as" :class="computedClass" v-bind="omit(attrs, 'class')">
      <slot></slot>
    </Button>
  </li>
</template>
