<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { computed, useAttrs } from 'vue';

defineOptions({ inheritAttrs: false });

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge([
    'text-xs text-slate-500',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <div :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </div>
</template>
