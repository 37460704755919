<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { MenuButton as HeadlessMenuButton } from '@headlessui/vue';
import { useAttrs, computed } from 'vue';

defineOptions({ inheritAttrs: false });

interface ButtonProps
  extends /* @vue-ignore */ ExtractProps<typeof HeadlessMenuButton> {
  as?: string | object;
}

const { as = 'div' } = defineProps<ButtonProps>();

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge(['cursor-pointer', typeof attrs.class === 'string' && attrs.class])
);
</script>

<template>
  <HeadlessMenuButton as="template">
    <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
      <slot></slot
    ></component>
  </HeadlessMenuButton>
</template>
