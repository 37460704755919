import { superAdminRoles } from '@/defaults/roles';

export default [
  {
    path: '/call-categories',
    name: 'call-categories',
    component: () => import('@/pages/CallCategory/CallCategories.vue'),
    meta: { title: 'Call Categories', roles: superAdminRoles },
  },
];
