<script setup lang="ts">
const {
  title,
  description,
  icon,
  iconColor = 'text-gray-400',
  loaderWidth,
} = defineProps<{
  title: String;
  description?: String;
  icon?: String;
  iconColor?: String;
  showLoader?: Boolean;
  loaderWidth?: Number;
}>();

const loaderIconWidth = loaderWidth || 40;
</script>

<template>
  <div class="text-center">
    <div v-if="showLoader" class="flex justify-center">
      <LoadingIcon :icon="icon" :width="loaderIconWidth" />
    </div>

    <Lucide
      v-else
      :icon="icon"
      class="mx-auto h-12 w-12 text-gray-400"
      :class="iconColor"
    />

    <h3 class="my-2 text-lg font-semibold text-gray-900">{{ title }}</h3>

    <p class="mt-1 text-sm text-gray-600">{{ description }}</p>

    <div class="mt-6">
      <slot></slot>
    </div>
  </div>
</template>
