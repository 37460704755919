<script setup lang="ts">
// Props
const { title } = defineProps<{ title: string }>();

// Emits
const emit = defineEmits<{ (e: 'close'): void }>();
</script>

<template>
  <div class="flex items-center justify-between">
    <SectionHeader :title="title" size="sm" class="flex-1 text-center" />

    <AppButton
      type="button"
      variant="text"
      aria-label="Close"
      class="p-1 rounded-full w-8 h-8 bg-transparent hover:bg-gray-200"
      @click="emit('close')"
    >
      <Lucide icon="X" class="w-5 h-5" />
      <span class="sr-only">Close</span>
    </AppButton>
  </div>
</template>
