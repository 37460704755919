import { UserRole } from '@/consts';

export default [
  {
    path: '/tax-documents',
    name: 'tax-documents',
    component: () => import('@/pages/TaxDocument/TaxDocuments.vue'),
    meta: { roles: UserRole.Client },
  },
  {
    path: '/tax-documents/:documentId/preview',
    name: 'tax-document.preview',
    component: () => import('@/pages/DMS/DocumentPreview.vue'),
    meta: { title: 'Tax Document Preview', roles: UserRole.Client },
  },
];
