import { ref } from 'vue';
import { defineStore } from 'pinia';

import { NotificationType } from '@/consts';
import axios from '@/services/axios';
import type { NotificationObject } from '@/types/common';

export const useCommonStore = defineStore('common', () => {
  // State
  const notification = ref<NotificationObject>({
    title: '',
    message: '',
    type: NotificationType.Success,
  });

  // Actions
  const setNotification = (data: NotificationObject | string) => {
    if (!data) return;

    clearNotification();

    if (typeof data === 'string') {
      data = { title: data };
    }

    if (!data.type) {
      data.type = NotificationType.Success;
    }

    notification.value = data;
  };

  const clearNotification = () => {
    notification.value = {
      title: '',
      message: '',
      type: NotificationType.Success,
    };
  };

  const getCurrentIPAddress = async (): Promise<any> => {
    return await axios.get('/v1/what-is-my-ip');
  };

  return {
    // State
    notification,

    // Actions
    setNotification,
    clearNotification,
    getCurrentIPAddress,
  };
});
