import { Setting } from '@/consts';
import { GeneralSettings } from '@/types/settings/general-settings';

const defaultGeneralSettingsForm: GeneralSettings = {
  [Setting.IpRestrictionEnabled]: false,
  [Setting.WhitelistedIps]: [],
  [Setting.BeginningFiscalYear]: 2016,
  [Setting.CurrentFiscalYear]: new Date().getFullYear(),
  [Setting.TrackerRefreshInterval]: 60,
};

export { defaultGeneralSettingsForm };
