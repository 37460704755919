<script setup lang="ts">
import { provide, useAttrs, computed, ref, type Ref, watch } from 'vue';
import { Dialog as HeadlessDialog, TransitionRoot } from '@headlessui/vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

defineOptions({ inheritAttrs: false });

type Size = 'sm' | 'md' | 'lg' | 'xl';

export type ProvideDialog = {
  open: boolean;
  zoom: Ref<boolean>;
  size?: Size;
};

interface DialogProps
  extends /* @vue-ignore */ ExtractProps<typeof HeadlessDialog> {
  size?: Size;
  open: boolean;
  staticBackdrop?: boolean;
}

const props = withDefaults(defineProps<DialogProps>(), {
  as: 'div',
  open: false,
  size: 'md',
});

const { as, onClose, size } = props;
const open = computed(() => props.open);
const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge(['relative z-[60]', typeof attrs.class === 'string' && attrs.class])
);

const zoom = ref(false);
const staticBackdrop = ref<boolean>(props.staticBackdrop);

const emit = defineEmits<{
  (e: 'close', value: boolean): void;
}>();

watch(
  () => props.staticBackdrop,
  value => {
    staticBackdrop.value = value;
  }
);

const handleClose = (value: boolean) => {
  if (!staticBackdrop.value) {
    onClose && onClose(value);
    emit('close', value);
  } else {
    zoom.value = true;
    setTimeout(() => {
      zoom.value = false;
    }, 300);
  }
};

provide<ProvideDialog>('dialog', {
  open: open.value,
  zoom: zoom,
  size: size,
});
</script>

<template>
  <TransitionRoot appear as="template" :show="open">
    <HeadlessDialog
      :as="as"
      @close="
        value => {
          handleClose(value);
        }
      "
      :class="computedClass"
      v-bind="omit(attrs, 'class', 'onClose')"
    >
      <slot></slot>
    </HeadlessDialog>
  </TransitionRoot>
</template>
