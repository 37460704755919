<script setup lang="ts">
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { computed, type TableHTMLAttributes, useAttrs, provide } from 'vue';

defineOptions({ inheritAttrs: false });

export type ProvideTable = {
  dark: boolean;
  bordered: boolean;
  hover: boolean;
  striped: boolean;
  sm: boolean;
};

interface TableProps extends /* @vue-ignore */ TableHTMLAttributes {
  dark?: boolean;
  bordered?: boolean;
  hover?: boolean;
  striped?: boolean;
  sm?: boolean;
}

const {
  dark = false,
  bordered = false,
  hover = false,
  striped = false,
  sm = false,
} = defineProps<TableProps>();

const attrs = useAttrs();

const computedClass = computed(() =>
  twMerge([
    'w-full text-left',
    dark && 'bg-dark text-white dark:bg-black/30',
    typeof attrs.class === 'string' && attrs.class,
  ])
);

provide<ProvideTable>('table', {
  dark: dark,
  bordered: bordered,
  hover: hover,
  striped: striped,
  sm: sm,
});
</script>

<template>
  <table :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </table>
</template>
