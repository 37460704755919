import { createApp } from 'vue';
import { createHead } from 'unhead';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import Aura from '@primevue/themes/aura';
import PrimeVue from 'primevue/config';

import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import registerGlobalComponents from './global-components';

const env = import.meta.env;
const app = createApp(App);

const sentryEnabled = env.VITE_SENTRY_ENABLED === 'true';

// Initialize Sentry
if (sentryEnabled) {
  Sentry.init({
    app,
    dsn: sentryEnabled ? env.VITE_SENTRY_DSN : '',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // 'localhost',
      // 'filethetax.test',
      'filethetax.com',
      'dev.filethetax.com',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// // Global error handler
// app.config.errorHandler = (err, vm, info) => {
//   console.error(err, vm, info);
// };

// // Enable performance tracing
// app.config.performance = true;

createHead();

registerGlobalComponents(app);

app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
  // unstyled: true, // Unstyled Mode
  theme: { preset: Aura },
});

app.mount('#app');
