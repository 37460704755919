<script setup lang="ts">
import {
  DialogPanel as HeadlessDialogPanel,
  TransitionChild,
} from '@headlessui/vue';
import omit from 'lodash/omit';
import { twMerge } from 'tailwind-merge';
import { type ProvideDialog } from './Dialog.vue';
import { inject, useAttrs, computed } from 'vue';

defineOptions({ inheritAttrs: false });

interface PanelProps
  extends /* @vue-ignore */ ExtractProps<typeof HeadlessDialogPanel> {
  as?: string | object;
}

const { as = 'div' } = defineProps<PanelProps>();

const dialog = inject<ProvideDialog>('dialog');

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge([
    'w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600',
    dialog?.size == 'md' && 'sm:w-[460px]',
    dialog?.size == 'sm' && 'sm:w-[300px]',
    dialog?.size == 'lg' && 'sm:w-[600px]',
    dialog?.size == 'xl' && 'sm:w-[600px] lg:w-[900px]',
    dialog?.zoom.value && 'scale-105',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <TransitionChild
    as="div"
    enter="ease-in-out duration-500"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-[400ms]"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    class="fixed inset-0 bg-black/60"
    aria-hidden="true"
  />
  <TransitionChild
    as="div"
    enter="ease-in-out duration-500"
    enterFrom="opacity-0 -mt-16"
    enterTo="opacity-100 mt-16"
    entered="opacity-100 mt-16"
    leave="ease-in-out duration-[400ms]"
    leaveFrom="opacity-100 mt-16"
    leaveTo="opacity-0 -mt-16"
    class="fixed inset-0"
  >
    <!-- Full-screen scrollable container -->
    <div class="fixed inset-0 w-screen overflow-y-auto">
      <!-- Container to center the panel -->
      <div class="min-h-full p-4 sm:p-6 lg:p-10">
        <HeadlessDialogPanel as="template">
          <component
            :is="as"
            :class="computedClass"
            v-bind="omit(attrs, 'class')"
          >
            <slot></slot>
          </component>
        </HeadlessDialogPanel>
      </div>
    </div>
  </TransitionChild>
</template>
