<script setup lang="ts">
// Props
const {
  type,
  checked = false,
  size = 24,
  onToggle = () => {},
} = defineProps<{
  type: 'radio' | 'checkbox';
  checked: boolean;
  size?: string | number;
  onToggle?: () => void;
}>();

// Emits
const emit = defineEmits(['update:checked']);

// Data Properties
const uncheckedColor = '#d1d9e0';

// Methods
const handleKeydown = (event: KeyboardEvent) => {
  // Toggle checked state on space or enter key
  if (event.key === ' ' || event.key === 'Enter') {
    event.preventDefault();
    onToggle();
  }
};
</script>

<template>
  <div
    class="cursor-pointer inline-flex items-center"
    role="button"
    tabindex="0"
    :aria-checked="checked"
    @click.prevent="onToggle"
    @keydown="handleKeydown"
  >
    <!-- Checkbox SVG Icons -->
    <template v-if="type === 'checkbox'">
      <svg
        v-if="checked"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        :width="size"
        :height="size"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :fill="uncheckedColor"
        :width="size"
        :height="size"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        />
      </svg>
    </template>

    <!-- Radio SVG Icons -->
    <template v-if="type === 'radio'">
      <svg
        v-if="checked"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
        :width="size"
        :height="size"
      >
        <path
          d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"
        />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        :fill="uncheckedColor"
        :width="size"
        :height="size"
      >
        <path
          d="M480.28-96Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
        />
      </svg>
    </template>
  </div>
</template>
