<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Link from './Link.vue';

// Types
interface DataTableProps {
  perPage: number;
  currentPage: number;
  pageCount: number;
}

type PageNumber = number | '...';

type Page = {
  number: PageNumber;
  isDisabled: boolean;
};

// Events
const emit = defineEmits<{
  (e: 'pageChange', page: number): void;
}>();

// Props
const props = withDefaults(defineProps<DataTableProps>(), {
  currentPage: 1,
  perPage: 10,
  total: 0,
});

// Data properties
const linksOnEachSide = ref(1);
const currentPage = ref(props.currentPage);

// Computed properties
const isFirstPage = computed(() => currentPage.value === 1);
const isLastPage = computed(() => currentPage.value === props.pageCount);

const pages = computed(() => {
  const pagesList: Page[] = [];

  // Set starting page to be 2 pages before the current page
  let start = currentPage.value - linksOnEachSide.value;
  // Set ending page to be 2 pages after the current page
  let end = currentPage.value + linksOnEachSide.value;

  if (start < 1) {
    // If start is less than 1, add the difference to the end
    end += 1 - start;
    start = 1;
  }

  if (end > props.pageCount) {
    // If end is greater than pageCount, subtract the difference from the start
    start -= end - props.pageCount;
    end = props.pageCount;
  }

  // Ensure start is at least 1
  start = Math.max(start, 1);

  for (let i = start; i <= end; i++) {
    pagesList.push({
      number: i,
      isDisabled: false,
    });
  }

  // Add ellipsis and last page if necessary
  if (end < props.pageCount) {
    if (end < props.pageCount - 1) {
      pagesList.push({
        number: '...',
        isDisabled: true,
      });
    }

    pagesList.push({
      number: props.pageCount,
      isDisabled: false,
    });
  }

  return pagesList;
});

// Watchers
watch(
  () => props.currentPage,
  value => {
    currentPage.value = value;
  }
);

// Methods
const handlePageChange = (page: PageNumber) => {
  if (page === '...') return;

  if (page < 1 || page > props.pageCount || page === props.currentPage) {
    return;
  }

  emit('pageChange', page);
};

const isPageActive = (page: PageNumber) => {
  return currentPage.value === page;
};

const disabledClassList = (isDisabled: boolean) => {
  return isDisabled ? 'cursor-not-allowed opacity-50' : '';
};
</script>

<template>
  <nav>
    <ul class="flex w-full mr-0 sm:w-auto sm:mr-auto">
      <Link
        :class="disabledClassList(isFirstPage)"
        :disabled="isFirstPage"
        @click="handlePageChange(1)"
      >
        <Lucide icon="ChevronsLeft" class="w-4 h-4" />
      </Link>

      <Link
        :class="disabledClassList(isFirstPage)"
        :disabled="isFirstPage"
        @click="handlePageChange(currentPage - 1)"
      >
        <Lucide icon="ChevronLeft" class="w-4 h-4" />
      </Link>

      <Link
        v-for="page in pages"
        @click="handlePageChange(page.number)"
        :disabled="page.isDisabled"
        :class="[
          {
            '!box font-medium dark:bg-darkmode-400': isPageActive(page.number),
          },
          disabledClassList(page.isDisabled),
        ]"
      >
        <Lucide
          v-if="page.number === '...'"
          icon="MoreHorizontal"
          class="w-4 h-4"
        />
        <template v-else>{{ page.number }}</template>
      </Link>

      <Link
        :class="disabledClassList(isLastPage)"
        :disabled="isLastPage"
        @click="handlePageChange(currentPage + 1)"
      >
        <Lucide icon="ChevronRight" class="w-4 h-4" />
      </Link>

      <Link
        :class="disabledClassList(isLastPage)"
        :disabled="isLastPage"
        @click="handlePageChange(props.pageCount)"
      >
        <Lucide icon="ChevronsRight" class="w-4 h-4" />
      </Link>
    </ul>
  </nav>
</template>
