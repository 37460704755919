import { defineStore } from 'pinia';
import { AxiosResponse } from 'axios';

import {
  HttpStatusCodes,
  NotificationType,
  ResponseStatus,
  UserRole,
  UserStatus,
} from '@/consts';
import { useCommonStore } from '@/stores/common';
import httpClient from '@/services/axios';
import router from '@/router';
import type { ApiResponse } from '@/types/api';
import type { AuthState, PasswordResetForm } from '@/types/auth';
import type { Role } from '@/types/role';
import type { User } from '@/types/user';

const env = import.meta.env;

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
  }),

  getters: {
    isAuthenticated(): boolean {
      return !!this.user;
    },

    roles(): UserRole[] {
      return this.user?.roles?.map((role: Role) => role.name) || [];
    },

    role(): UserRole {
      return this.roles[0];
    },

    isDeveloper(): boolean {
      return this.roles.includes(UserRole.Developer);
    },

    isSuperAdmin(): boolean {
      return this.roles.includes(UserRole.SuperAdmin);
    },

    isAdmin(): boolean {
      return this.roles.includes(UserRole.Admin);
    },

    isReviewer(): boolean {
      return this.roles.includes(UserRole.Reviewer);
    },

    isCaller(): boolean {
      return this.roles.includes(UserRole.Caller);
    },

    isPreparer(): boolean {
      return this.roles.includes(UserRole.Preparer);
    },

    isClient(): boolean {
      return this.roles.includes(UserRole.Client);
    },

    hasSuperAdminAccess(): boolean {
      return this.isDeveloper || this.isSuperAdmin;
    },

    doesNotHaveSuperAdminAccess(): boolean {
      return !this.hasSuperAdminAccess;
    },

    hasAdminAccess(): boolean {
      return this.isDeveloper || this.isSuperAdmin || this.isAdmin;
    },

    doesNotHaveAdminAccess(): boolean {
      return !this.hasAdminAccess;
    },

    hasEmployeeAccess(): boolean {
      return this.isReviewer || this.isCaller || this.isPreparer;
    },
  },

  actions: {
    async login(form: any): Promise<any> {
      const response = await httpClient.post('/v1/login', form);
      const result = response?.data;

      if (!result.data) {
        return response;
      }

      localStorage.setItem(env.VITE_AUTH_TOKEN_NAME, result?.data?.token || '');

      this.setUser(result?.data?.user || null);

      const commonStore = useCommonStore();
      const message = result?.message || '';

      if (result?.status === ResponseStatus.Success) {
        commonStore.setNotification(message);
      } else {
        commonStore.setNotification({
          title: 'Login failed',
          message,
          type: NotificationType.Danger,
        });
      }

      return response;
    },

    async register(form: any): Promise<any> {
      const response = await httpClient.post('/v1/register', form);
      return response;
    },

    async logout(): Promise<void> {
      if (!localStorage.getItem(env.VITE_AUTH_TOKEN_NAME)) return;

      try {
        await httpClient.post('/v1/logout');
        this.setUser(null);
        localStorage.removeItem(env.VITE_AUTH_TOKEN_NAME);
        router.push({ name: 'login' });
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },

    async verifyEmail(payload: { email: string; otp: string }): Promise<any> {
      const response = await httpClient.post('/v1/email/verify', payload);
      return response;
    },

    async sendOtp(email: string): Promise<any> {
      const response = await httpClient.post('/v1/otp/send', { email });
      return response;
    },

    async verifyOtp(payload: { email: string; otp: string }): Promise<any> {
      const response = await httpClient.post('/v1/otp/verify', payload);
      return response;
    },

    async getCurrentUser(): Promise<
      AxiosResponse<ApiResponse<User>> | undefined
    > {
      if (!localStorage.getItem(env.VITE_AUTH_TOKEN_NAME)) return;

      const response = await httpClient.get('/v1/user');

      if (
        response.status === HttpStatusCodes.Unauthorized ||
        response.data?.data?.status !== UserStatus.Active
      ) {
        this.logout();
        return;
      }

      if (response.status === HttpStatusCodes.Ok) {
        this.setUser(response.data?.data || null);
        return;
      }

      return response;
    },

    async resetPassword(
      payload: PasswordResetForm
    ): Promise<AxiosResponse<ApiResponse<string>>> {
      return await httpClient.post('/v1/password/reset', payload);
    },

    setUser(user: User | null) {
      this.user = user;
    },

    resetSession(): void {
      this.setUser(null);
      localStorage.removeItem(env.VITE_AUTH_TOKEN_NAME);
    },

    hasRole(role: UserRole): boolean {
      return this.hasAnyRole(role);
    },

    hasAnyRole(roles: UserRole | UserRole[]): boolean {
      if (!this.user) {
        return false;
      }

      if (Array.isArray(roles)) {
        return roles.some(role => this.roles.includes(role));
      }

      return this.roles.includes(roles);
    },
  },
});
