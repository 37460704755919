import { UserRole } from '@/consts';

export default [
  {
    path: '/taxpayers',
    name: 'taxpayers',
    component: () => import('@/pages/Taxpayer/Taxpayers.vue'),
    meta: { title: 'Taxpayers', roles: UserRole.Client },
  },
  {
    path: '/taxpayers/create',
    name: 'taxpayers.create',
    component: () => import('@/pages/Taxpayer/CreateTaxpayer.vue'),
    meta: { title: 'Add Taxpayer', roles: UserRole.Client },
  },
  {
    path: '/taxpayers/:taxpayerId/edit',
    name: 'taxpayers.edit',
    component: () => import('@/pages/Taxpayer/EditTaxpayer.vue'),
    meta: { title: 'Edit Taxpayer Details', roles: UserRole.Client },
  },
];
