<script setup lang="ts">
import { useThemeStore, getTheme } from '@/stores/theme';
import { computed } from 'vue';

const themeStore = useThemeStore();
const Component = computed(() => getTheme(themeStore.theme).component);
</script>

<template>
  <div>
    <Component />
  </div>
</template>
