import { UserRole } from '@/consts';
import {
  adminRoles,
  allRoles,
  callRecordsRoles,
  dmsRoles,
  superAdminRoles,
  trackerRoles,
  userModuleRoles,
} from '@/defaults/roles';
import { type Menu } from '@/stores/menu';

const menu: Array<Menu | 'divider'> = [
  // Dashboard
  {
    icon: 'LayoutDashboard',
    pageName: 'dashboard',
    title: 'Dashboard',
    meta: { roles: allRoles },
  },
  // Taxpayers
  {
    icon: 'Users',
    pageName: 'taxpayers',
    title: 'Taxpayers',
    meta: { roles: [UserRole.Client] },
  },
  // Tracker
  {
    icon: 'SquareKanban',
    pageName: 'tracker',
    title: 'Tracker',
    meta: { roles: trackerRoles },
  },
  // DMS
  {
    icon: 'Folders',
    pageName: 'dms',
    title: 'DMS',
    meta: { roles: dmsRoles },
  },
  // Calls Assignment
  {
    icon: 'Headset',
    pageName: 'call-records.management',
    title: 'Calls Management',
    meta: { roles: superAdminRoles },
  },
  // Call Records
  {
    icon: 'Phone',
    pageName: 'call-records',
    title: 'Call Records',
    meta: { roles: callRecordsRoles },
  },
  // Call Categories
  {
    icon: 'Tags',
    pageName: 'call-categories',
    title: 'Call Categories',
    meta: { roles: superAdminRoles },
  },
  // Call Slots
  {
    icon: 'Component',
    pageName: 'call-slots',
    title: 'Call Slots',
    meta: { roles: superAdminRoles },
  },
  // Users
  {
    icon: 'Users',
    pageName: 'users',
    title: 'Users',
    meta: { roles: userModuleRoles },
  },
  // Tax Document Types
  {
    icon: 'FileText',
    pageName: 'tax-document-types',
    title: 'Tax Document Types',
    meta: { roles: adminRoles },
  },
  // Upload Documents
  {
    icon: 'FileUp',
    pageName: 'tax-documents',
    title: 'Tax Documents',
    meta: { roles: [UserRole.Client] },
  },
  // Tracker Stages
  {
    icon: 'Kanban',
    pageName: 'tracker.stages',
    title: 'Stages',
    meta: { roles: superAdminRoles },
  },
  // Tracker Labels
  {
    icon: 'Tags',
    pageName: 'tracker.services',
    title: 'Services',
    meta: { roles: superAdminRoles },
  },
  // Developer Only
  {
    icon: 'Code',
    pageName: '',
    title: 'Developer Only',
    meta: { roles: [UserRole.Developer] },
    subMenu: [
      // Options
      {
        icon: 'SlidersHorizontal',
        pageName: 'options',
        title: 'Options',
        meta: { roles: [UserRole.Developer] },
      },
      // Roles
      {
        icon: 'Shield',
        pageName: 'roles',
        title: 'Roles',
        meta: { roles: [UserRole.Developer] },
      },
    ],
  },
];

export default menu;
