<script setup lang="ts">
import { useAttrs, computed } from 'vue';
import { twMerge } from 'tailwind-merge';
import omit from 'lodash/omit';

defineOptions({ inheritAttrs: false });

interface FooterProps {
  as?: string | object;
}

const { as = 'div' } = defineProps<FooterProps>();

const attrs = useAttrs();
const computedClass = computed(() =>
  twMerge([
    'px-5 py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400',
    typeof attrs.class === 'string' && attrs.class,
  ])
);
</script>

<template>
  <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
    <slot></slot>
  </component>
</template>
